import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-showcase',
  template: '<div class="mb-component-showcase-wrapper"><div class="mb-component-showcase-tagline">{{showcaseTagLineOne}}<span class="mb-component-showcase-tagline-handwrite">{{showcaseTagLineHandWrite}}</span>{{showcaseTagLineTwo}}</div>',
  styleUrls: ['./showcase.component.css']
})
export class ShowcaseComponent {

  @Input() showcaseMedia: string;
  @Input() showcaseTagLineOne: string;
  @Input() showcaseTagLineTwo: string;
  @Input() showcaseTagLineHandWrite: string;

}
