import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inquiry-form',
  templateUrl: './inquiry-form.component.html',
  styleUrls: ['./inquiry-form.component.css']
})
export class InquiryFormComponent implements OnInit {

  @Input() inquiryDescriptionInterview: string;
  @Input() inquiryDescriptionBToB: string;
  @Input() inquiryDescriptionHost: string;
  @Input() inquiryDescriptionDigitalServices: string;
  @Input() testVar: string;

  prospectSelect(event, prospect) {
    //this.inquiryDescription = event.target.value;
    if(prospect == "interview") {
      this.inquiryDescriptionInterview = "Selected " + event.target.value;
      if(!event.target.checked) {
        this.inquiryDescriptionInterview = "";
      }
    } else if(prospect == "b2b") {
      this.inquiryDescriptionBToB = "Selected " + event.target.value;
      if(!event.target.checked) {
        this.inquiryDescriptionBToB = "";
      }
    } else if(prospect == "host") {
      this.inquiryDescriptionHost = "Selected " + event.target.value;
      if(!event.target.checked) {
        this.inquiryDescriptionHost = "";
      }
    } else if(prospect == "digital") {
      this.inquiryDescriptionDigitalServices = "Selected " + event.target.value;
      if(!event.target.checked) {
        this.inquiryDescriptionDigitalServices = "";
      }
    }
    console.log(event.target.checked);

  }

  constructor() { }

  ngOnInit() {
    //this.inquiryDescription = 'not clicked';

  }

}
