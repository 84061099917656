import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ShowcaseComponent } from './showcase/showcase.component';
import { InquiryFormComponent } from './inquiry-form/inquiry-form.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [HeaderComponent, FooterComponent, ShowcaseComponent, InquiryFormComponent],
  exports: [
  HeaderComponent,
  FooterComponent,
  ShowcaseComponent,
  InquiryFormComponent
]
})
export class FragmentsModule { }
